import React from 'react'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface FragmentProps {
  fields: any
  location?: any
  pageContext?: any
}

const Fragment: React.FC<FragmentProps> = ({
  fields,
  location = {},
  pageContext,
}) => (
  <FlexContentHandler
    prefix="Fragment_FlexFragment"
    fields={fields.element.flex_fragment}
    location={location}
    pageContext={pageContext}
  />
)

export default Fragment
